:root {
    --animate-duration:2000ms;
  }
  
.ourProjects{
    padding-top: 80px;
}
.ourProjectsTitle{
    font-family: Kanit;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 90%;
    color: #3A3A3A;
    text-align: center;
}
.slider{
    padding-top: 45px;
}

.swiper-slide img{
    margin-bottom: 5px;
  }
.ourProjectsInfo{
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ourProjectsInfoNumber{
    padding: 0 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 90%;
    color: #EF6630;
}
.ourProjectsInfoText{
    padding: 0 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 100%;
    color: #3A3A3A;
}
.ourProjectsDot{
    padding-top: 5px;
}
@media(max-width:1000px){
    .ourProjects{
        padding-top: 90px;
    }
    .slider{
        padding-top: 30px;
    }
    .ourProjectsTitle{
        font-size: 50px;
    }
    .ourProjectsInfoNumber{
        font-size: 45px;
    }
    .ourProjectsInfoText{
        font-size: 24px;
    }
    .ourProjectsDot{
        width: 15px;
    }
    .carimg{
        width: 97%;
      }
}

@media(max-width:550px){
    .ourProjects{
        padding-top: 60px;
    }
    .ourProjectsTitle{
        font-size: 30px;
    }
    .ourProjectsInfoNumber{
        padding: 0 0;
    }
    .ourProjectsInfoNumber{
        font-size: 30px;
    }
    .ourProjectsInfoText{
        font-size: 16px;
    }
    .ourProjectsInfo{
        padding-top: 20px;
    }
    .ourProjectsDot{
        width: 10px;
    } 
}