
*{
  margin: 0;
  padding: 0;
}
body{
  color: #3A3A3A;
}
a{
  color: #fff;
  text-decoration: none;
}
  .container{
    max-width: 1180px;
    margin: 0 auto;
  }
  
  @media(max-width:1280px){
    .container{
      max-width: 1000px;
    }
  }
  @media(max-width:1000px){
    .container{
      max-width: 770px;
    }
  }
@media(max-width:768px){
  .container{
    max-width: 640px;
    margin: 0 auto;
  }
}
@media(max-width:550px){
  .container{
    max-width: 400px;
    margin: 0 auto;
  }
}
@media(max-width:400px){
  .container{
    max-width: 315px;
  }
}
