:root {
    --animate-duration: 2000ms;
  }
  
.servicesBlock{
    margin-top: 130px;   
}
.servicesBlockTitle{
    font-family: Kanit;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 90%;
    color: #3A3A3A;
    padding-bottom: 60px;
}
.serviceSlide{
    display: flex;
}
.serviceInfoTitle{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 90%;
    color: #EF6630;
    margin-right: 15px;
}
.serviceInfoText h4{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 100%;
    color: #3A3A3A;
    width: 216px;
    margin-bottom: 20px;
}
.serviceInfoText p{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #3A3A3A;
}
.serviceSlider .swiper-container{
    height: 180px;
}
.serviceSlider .swiper-pagination-bullet{
    width: 24px;
    height: 5px;
    border-radius: 2px;
    background: rgba(158, 158, 158, 0.64);

}
@media(max-width:1000px){
    .servicesBlockTitle{
        font-size: 50px;
    }
}
@media(max-width:550px){
    .servicesBlock{
        margin-top: 90px;   
    }
    .servicesBlockTitle{
        font-size: 30px;
        padding-bottom: 35px;
    }
    .serviceInfoText h4{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .serviceInfoText p{
        font-size: 16px;
    }
    .swiper-pagination{
        display: none;
    }
}

