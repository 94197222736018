:root {
    --animate-duration: 2000ms;
  }
  
.ourClients{
    padding-bottom: 150px;
    display: flex;
    justify-content: center;
}
.ourClientsTitle{
    font-family: Kanit;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 90%;
    color: #3A3A3A;
    text-align: center;
}
.ourClientsImgBlock{
    margin-top: 55px;
}
.ourClientsImg{
    margin-right: 50px;
}
.swiper-wrapper{
 align-items: center;
}
.client{
    text-align: center;
}
@media(max-width:1280px){
    .ourClientsTitle{
        font-size: 50px;
    }
    .ourClientsImgBlock{
        margin-top: 45px;
        justify-content: center;
    }
}
@media(max-width:768px){
    .ourClients{
        padding-bottom: 80px;
    }
    .ourClientsTitle{
        font-size: 50px;
    }
    .ourClientsImgBlock{
        margin-top: 40px;
        justify-content: center;
    }
    .ourClientsImg{
        margin-right: 30px;
    }
    .img1{
        width: 100px;
    }
    .img2{
        width: 150px;
    }
    .img3{
        width: 75px;
    }
    .img4{
        width: 150px;
    }
    .img5{
        width: 145px;
    }
    .img6{
        width: 50px;
    }
    .img7{
        width: 80px;
    }
    .img8{
        width: 180px;
    }
    
}
@media(max-width:550px){
    .ourClientsTitle{
        font-size: 30px;
    }
    .ourClientsImg{
        margin-right: 20px;
    }
    .img1{
        width: 91px;
    }
    .img2{
        width: 110px;
    }
    .img3{
        width: 69px;
    }
    .img4{
        width: 120px;
    }
    .img5{
        width: 145px;
    }
    .img6{
        width: 40px;
    }
    .img7{
        width: 50px;
    }
    .img8{
        width: 100px;
    }
}
@media(max-width:400px){
    .ourClientsImg{
        margin-right: 10px;
    }
}