:root {
    --animate-duration: 2000ms;
  }
  
.aboutUs{
    overflow: hidden;
    padding-top: 200px;
    margin-bottom: 170px;
}
.aboutUsBlock{
    display:flex;
}
.aboutUsDes{
    padding-left: 60px;  
}
.aboutIpadImg{
    display: none;
}
.aboutUsTitle{
    font-family: Kanit;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 90%;
    color: #3A3A3A;
    padding-top: 22px;
    margin-bottom: 30px;
}
.AboutUsText{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #3A3A3A;
    padding-bottom: 15px;
}
@media(max-width:1300px){
    .AboutUsText{
        font-size: 19px;
    }
}
@media(max-width:1000px){
    .aboutUs{
        margin-bottom: 150px;
    }
    .aboutUsBlock{
        display: block;
    }
    .aboutUsImg{
        display: none;
    }
    .aboutIpadImg{
        display: block;
        width: 100%;
    }
    .aboutUsDes{
        padding-left: 0;
    }
    .aboutUsTitle{
        font-size: 50px;
    }
    .AboutUsText{
        font-size: 20px;
    }
}
@media(max-width:550px){
    .aboutUs{
        margin-bottom: 90px;
        padding-top: 50px;
    }
    .aboutUsTitle{
        font-size: 30px;
        padding-top: 22px;
        margin-bottom: 15px;
    }
    .AboutUsText{
        font-size: 16px;
    }
}