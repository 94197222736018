
.header{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 40px;
    animation: slide 15s infinite;   
  }
.topnav{
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-bottom: 413px;
}
.logo{
    padding-top: 49px;     
}

.topnavMenu{
    padding-left: 49px;
    padding-top: 49px;
    display: flex;
}
.topnavMenu ul li{
    list-style: none;
    display: inline-block;
}
.topnavMenu ul li a{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    padding:13px;
}
.BurgerMenu{
    display: none;
}
.BurgerMenu .menu,
.BurgerMenu .close{
    cursor: pointer;
    display: none;
}
.headerContentTitle{
    font-family: Kanit;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 90px;
    line-height: 90%;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.headerContentText{
    width: 100%;
    height: 60px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 100%;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.btnBlock{
    display: flex;
}
.headerContentBtn{
    width: 132px;
    border-radius: 5px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 13px 37px 15px;
    color: #3A3A3A;
    font-family: Lato;
    font-size: 18px;
}
.headerContentBtn2{
    margin-left: 20px;
    background: #EF6630;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 54px 15px; 
    font-family: Lato;
    font-size: 18px;
}
@keyframes slide {
    0%{
        background-image: url('../../img/mainslide1.png');
        opacity: 1;
    }
    40%{
        background-image: url('../../img/mainslide1.png');
        opacity: 0.8;
        
    }
    40%{
        background-image: url('../../img/mainslide2.png');
        opacity: 1;
    }
    60%{
        background-image: url('../../img/mainslide2.png');
        opacity: 0.8;
    }
    60%{
        background-image: url('../../img/mainslide3.png');
        opacity: 1;
    }
    100%{
        background-image: url('../../img/mainslide3.png');
        opacity: 0.9;
    }
    100%{
        background-image: url('../../img/mainslide1.png');
        opacity: 1;
    }
}
@media(max-width:1400px){
    .logo{
        padding-top: 40px;     
    }
    .topnav{
        margin-bottom: 281px;
    }
    .topnavMenu{
        padding-top: 40px;
    }
    .topnavMenu ul li a{
        font-size: 20px;
    }
    .headerContentTitle{
        font-size: 70px;
    }
    .headerContentText{
        font-size: 24px;
        margin-bottom: 10px;
    }
    .headerContentBtn{
        padding: 13px 0 15px;
        width: 160px;
        font-size: 16px;
    }
    .headerContentBtn2{
        padding: 13px 45px 15px; 
        font-size: 16px;
    }
}
@media(max-width:550px){
    .topnav{
        margin-bottom: 291px;
        display: flex;
        justify-content: space-between;
    }
    .topnavMenu{
        display: none;
    }
    .header{
        background-image: url('../../img/phoneImg.png');
        padding-bottom: 20px;
        background-size: cover;
    }
    .logo{
        padding-top: 20px;  
    }
    .logo img{
        width: 35px;
        height: 35px;
    }
    
    .headerContentTitle{
        font-size: 50px;
    }
    .headerContentText{
        font-size: 18px;
    }
    .headerContentBtn{
        padding: 0px 0 0px;
        width: 140px;
        font-size: 16px;
    }
    .headerContentBtn2{
        padding: 9px 30px 9px; 
        font-size: 16px;
        margin-left: 10px;
    }
}


/* Burger Menu style */
@media (max-width: 550px){
    .BurgerMenu{
        display: block;
    }
    .BurgerMenu nav{
        position: fixed;
        top:-100%;
        left: 0;
        width: 100%;
        height: 261px;
        background: white;
        z-index: 99;
        opacity: 0.97;
        transition: 0.5s linear;
    }
    .BurgerMenu ul{
        padding-top: 65px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }
    .BurgerMenu ul li{
        list-style: none;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #3A3A3A;
        padding-bottom: 24px;
    }
    .BurgerMenu ul li a{
        color: black;
    }
    .BurgerMenu .menu,
    .BurgerMenu .close{
        display: block;
    }
    .BurgerMenu .menu img{
        width: 31px;
        height: 20px;
    }
    .BurgerMenu .close img{
        width: 31px;
        height: 20px;
    }
    .BurgerMenu .close{
        position: absolute;
        top:27px;
        right: 20px;
    }
    .BurgerMenu .menu{
        margin-top: 20px;
    }
    .BurgerMenu nav.toggle{
        top: 0;
    }
}