:root {
    --animate-duration: 2000ms;
  }
.contact{
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
}
.contactTitle{
    font-family: Kanit;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 90%;
    color: #3A3A3A;
}
.contactInfo{
    display: flex;
    padding-top: 40px;
    width: 477px;
}
.contactInfoTitle{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 90%;
    color: #EF6630;
}
.contactInfoText{
    padding-left:20px;
}
.contactInfoText h4{
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    color: #3A3A3A;
}
.contactInfoText p{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #3A3A3A;
    padding-top: 10px;
}
.contactForm{
    border-radius: 5px;
    padding-top: 40px;
    width: 387px;
}
input,textarea{
    width: 100%;
    height: 36px;
    padding: 10px 15px;
    margin: 8px 0;
    display: inline-block;
    background: rgba(255, 255, 255, 0.25);
    border: 2px solid #828282;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: Lato;
    color: #828282;
  }
  
  textarea{
    height: 85px;
  }
  label{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #3A3A3A;
    margin-bottom: 15px;
  }
  .formBtn{
    background: #EF6630;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 54px 15px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #FFFFFF;
    border: none;
  }
  
  @media(max-width:1000px){
      .contact{
          display: inline-block;
      }
      .contactTitle{
          font-size: 50px;
      }
      .contactInfo{
          width: 100%;
      }
      .contactInfoTitle{
          font-size: 50px;
      }
      .contactForm{
          width: 427px;
          padding-left: 90px;
      }
      .btn{
          display: flex;
          justify-content: center;
      }
  }
  @media(max-width:550px){
    .contactTitle{
        font-size: 30px;
    }
    .contactForm{
        width: 315px;
        padding-left: 0px;
        padding-top: 60px;
    }
    .contactInfoTitle{
        font-size: 45px;
    }
    .contactInfoText h4{
        font-size: 22px;
    }
    .contactInfoText p{
        font-size: 16px;
    }
  }