.footer{
    padding-bottom: 52px;
}
.footerIcons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.footerIcons img{
    margin: 10px;
}
.footerIcons img:last-child{
    margin-right: 0;
}